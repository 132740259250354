import { useCallback } from "react";
import { useLocale } from "../lib/tools/helpers";

export default function useFormatDate() {
  const lang = useLocale();

  const formatDate = useCallback(
    (dateString) => {
      let language = lang;
      switch (lang) {
        case "pt":
          language = "pt-BR";
          break;
        default:
          language = "en-US";
      }
      const originalDate = new Date(dateString);

      const options = { year: "numeric", month: "short", day: "numeric" };
      return originalDate.toLocaleDateString(language, options);
    },
    [lang]
  );

  return {
    formatDate,
  };
}
