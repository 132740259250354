import PubSub, { EVENTS } from "@lib/pubsub";
import { warn } from "@lib/tools/logger";
import dictionaryAtom from "@recoil/languageAtom";

import React, {
  createElement,
  Fragment,
  HTMLAttributes,
  isValidElement,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useRecoilValue } from "recoil";

type Dict = {
  [key: string]: string | Dict;
};

const getSection = (dict: Dict, section: string[] | string) => {
  if (typeof section === "string") return dict[section];

  // Check if the 'section' parameter is an array
  if (Array.isArray(section))
    // If 'section' is an array, merge all the corresponding language sections
    return section.reduce((acc, item) => {
      const v = dict[item];

      // ignore since not a section
      if (typeof v === "string") return acc;

      return { ...acc, ...v };
    }, {} as Dict);

  return dict;
};

/**
 * @deprecated use useDict instead
 */
const getText =
  (section) =>
  /**
   * @deprecated use useDict instead
   */
  // eslint-disable-next-line react/display-name
  (key, replaceMap = {}) => {
    const parts = key
      ?.split?.(".")
      ?.reduce((o, i) => o?.[i], section)
      ?.split(/{{(.*?)}}/)
      ?.map((part) => replaceMap[part] || part);

    if (!parts) {
      warn(`Did not find key "${key}" in the dictionary`);
      return key || "";
    }

    const isJSX = parts.some(isValidElement);

    const __html = isJSX
      ? parts.map((part, i) => <Fragment key={i}>{part}</Fragment>)
      : parts.join("");

    if (isJSX) return __html;

    return __html.includes("<") ? (
      <div dangerouslySetInnerHTML={{ __html }} />
    ) : (
      __html
    );
  };

/**
 * @deprecated use useDict instead
 */
export default function useLanguage(section_: string[] | string = ["common"]) {
  const { current: sectionName } = useRef(section_); // Store the section in a ref to prevent re-renders

  const dictionary = useRecoilValue(dictionaryAtom);

  /**
   * @deprecated
   * @type {(key: string, replaceMap: object) => string | JSX.Element}
   */
  const textFn = useMemo(() => {
    const section = getSection(
      dictionary || PubSub.state[EVENTS.DICT] || {},
      sectionName
    );

    return getText(section);
  }, [sectionName, dictionary]);

  return textFn;
}

// empty object to fix reference issues
const emptyObj = {} as Record<string, unknown>;
type DictProps = {
  section: string[] | string;
  as?: string;
  name: string;
  replacements?: Record<string, unknown>;
} & HTMLAttributes<HTMLElement>;

export const Dict = memo<DictProps>(function Dict({
  section: section_,
  as = "p",
  name,
  replacements = emptyObj,
  ...props
}) {
  const dictionary = useRecoilValue(dictionaryAtom);
  const { current: sectionName } = useRef(
    typeof section_ === "string" ? section_.split(" ") : section_
  ); // Store the section in a ref to prevent re-renders
  const section = useMemo(
    () =>
      getSection(dictionary || PubSub.state[EVENTS.DICT] || {}, sectionName),
    [sectionName, dictionary]
  );

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV !== "production")
      if (Object.keys(replacements).length < 1 && replacements !== emptyObj)
        warn("replacements with value of {} will result in an infinite loop.");
  }, [replacements]);

  const text = useMemo(() => {
    const parts = name
      ?.split?.(".")
      ?.reduce((o, i) => {
        if (typeof o === "string") return o;

        return o[i] || i;
      }, section as string)
      ?.split(/{{(.*?)}}/)
      ?.map((part) => (replacements[part] || part) as string | ReactNode);

    if (!parts) {
      warn(`Did not find key "${name}" in the dictionary`);
      return name || "";
    }

    const isJSX = parts.some(isValidElement);

    return isJSX
      ? parts.map((part, i) => <Fragment key={i}>{part}</Fragment>)
      : parts.join("");
  }, [name, replacements, section]);

  return createElement(
    as,
    {
      dangerouslySetInnerHTML:
        typeof text === "string" ? { __html: text } : undefined,
      ...props,
    },
    typeof text !== "string" ? text : undefined
  );
});

/**
 * @example
 * const Dict = useDict(["pageContent", "common"]);
 * return <Dict name="homeBanner.promotion_sqaure.subheading" as="p" />
 */
export const useDict = (section_ = ["common"]) => {
  const sectionRef = useRef(section_);

  return useCallback(
    ({ as = "p", name, replacements = emptyObj, ...props }) => {
      return (
        <Dict
          section={sectionRef.current}
          as={as}
          name={name}
          replacements={replacements}
          {...props}
        />
      );
    },
    []
  );
};

/**
 * @deprecated
 * @template { string } T
 * @param { string } section
 * @param  {...T} keys
 * @returns { Record<T, string> }
 */
export const useDictionary = (section, ...keys) => {
  const L = useLanguage(section);

  const o = useMemo(
    () =>
      keys.reduce((acc, key) => {
        acc[key] = L(key);
        return acc;
      }, {}),
    [L, keys]
  );

  return o;
};
