import Head from "next/head";
import { useRouter } from "next/router";
import { memo } from "react";

const defaultImage = "https://cdn.rainbet.com/brand/og-banner.webp";
const isDefaultImage = (image) => image === defaultImage;

function MetaContent(props) {
  const {
    meta,
    gamesMeta,
    gameName,
    caseOpenMeta,
    caseOpenName,
    caseOpenIcon,
    slotsMeta,
    gamesMetaIcon,
    providerMeta,
    providerName,
  } = props;
  let title = "Rainbet";
  let description = "";
  let image = "";
  let canonicalLink = "";

  let { asPath, locale: l } = useRouter();
  const baseUrl = asPath.split("?")[0];
  const locale = l === "en" ? "" : `/${l}`;
  const host = process.env.NEXT_PUBLIC_LIVE_URL + locale;

  switch (true) {
    case !!meta:
      title = meta?.meta?.title || meta?.title || "Rainbet";
      description = meta?.description || meta?.meta?.description || "";
      image = meta?.image || meta?.meta?.image || defaultImage;
      canonicalLink = meta.url ? `${meta.url}` : `${host}${baseUrl}`;

      break;
    case !!gamesMeta:
      title = gamesMeta.title;
      description = gamesMeta.description ? gamesMeta.description : "";
      image = gamesMetaIcon;
      canonicalLink = `${host}/casino/originals/${gameName}`;

      break;
    case !!caseOpenMeta:
      title = caseOpenName
        ? caseOpenMeta.title
        : caseOpenName + " Case" + " - " + "Rainbet";
      description = caseOpenMeta.description ? caseOpenMeta.description : "";
      image = caseOpenIcon;
      canonicalLink = `${caseOpenMeta?.url}`;
      break;
    case !!providerMeta:
      title = providerName + " Casino Games – Rainbet";
      description =
        "Experience games by " +
        providerName +
        " at Rainbet, offering the best slots, blackjack, roulette, and other table/live games.";
      canonicalLink = `${host}`;

      break;
    case !!slotsMeta:
      title = slotsMeta?.meta?.title
        ? slotsMeta?.meta?.title
        : "Slots – Rainbet";
      description = slotsMeta?.meta?.description
        ? slotsMeta?.meta?.description
        : "Rainbet Slots";
      image = slotsMeta?.icon;
      canonicalLink = `${slotsMeta?.meta?.url}`;

      break;
    default:
      title = "Rainbet";
      description =
        "Play casino game modes such as Slots, Blackjack, Baccarat, Roulette, Game Shows, Live Casino games and original RainBet games.";
      image = defaultImage;
      canonicalLink = `${host}`;
      break;
  }

  return (
    <Head>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
      <meta property="og:site_name" content="Rainbet" />
      <meta property="og:type" content="website" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />

      {isDefaultImage(image) && (
        <>
          <meta property="og:image:type" content="image/webp" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </>
      )}
      <meta property="og:image" content={image} />
    </Head>
  );
}

export default memo(MetaContent);
